@import "https://cdn.jsdelivr.net/npm/bulma@0.9.4/css/bulma.min.css";
.modal {
  background-color: #0b0b0bbf;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  transition: all .3s ease-in-out !important;
}

.close {
  cursor: pointer;
  font-size: 20px;
  position: absolute;
  top: 20px;
  right: 20px;
}

@media (width <= 600px) {
  .iframe-map {
    width: 328px !important;
    height: 262px !important;
  }

  #copyCCI, #copyAccount {
    right: 10px !important;
  }

  #btnFalabella {
    width: 100% !important;
  }
}

@media (height <= 768px) {
  #modalTableGift .modal-content {
    max-height: calc(100vh - 40px) !important;
  }
}

:root {
  --pilsener-100: #feffee;
  --pilsener-200: #fcffdd;
  --pilsener-300: #f9ffba;
  --pilsener-400: #f7ff98;
  --pilsener-500: #f4ff75;
  --pilsener-600: #f1ff53;
  --pilsener-700: #c1cc42;
  --pilsener-800: #919932;
  --pilsener-900: #606621;
  --pilsener-1000: #303311;
  --cadmium-yellow-100: #fffee6;
  --cadmium-yellow-200: #fffdcc;
  --cadmium-yellow-300: #fffb99;
  --cadmium-yellow-400: #fff866;
  --cadmium-yellow-500: #fff633;
  --cadmium-yellow-600: #fff400;
  --cadmium-yellow-700: #ccc300;
  --cadmium-yellow-800: #999200;
  --cadmium-yellow-900: #666200;
  --cadmium-yellow-1000: #333100;
  --spectra-yeloow-100: #fef8e8;
  --spectra-yeloow-200: #fdf0d0;
  --spectra-yeloow-300: #fbe2a1;
  --spectra-yeloow-400: #fad373;
  --spectra-yeloow-500: #f8c544;
  --spectra-yeloow-600: #f6b615;
  --spectra-yeloow-700: #c59211;
  --spectra-yeloow-800: #946d0d;
  --spectra-yeloow-900: #624908;
  --spectra-yeloow-1000: #624908;
  --orange-bell-pepper-100: #fff3ec;
  --orange-bell-pepper-200: #ffe7d9;
  --orange-bell-pepper-300: #ffcfb3;
  --orange-bell-pepper-400: #ffb68e;
  --orange-bell-pepper-500: #ff9e68;
  --orange-bell-pepper-600: #ff8642;
  --orange-bell-pepper-700: #cc6b35;
  --orange-bell-pepper-800: #995028;
  --orange-bell-pepper-900: #66361a;
  --orange-bell-pepper-1000: #331b0d;
  --vibrant-orange-100: #fff1e9;
  --vibrant-orange-200: #ffe2d2;
  --vibrant-orange-300: #ffc6a6;
  --vibrant-orange-400: #ffa979;
  --vibrant-orange-500: #ff8d4d;
  --vibrant-orange-600: #ff7020;
  --vibrant-orange-700: #cc5a1a;
  --vibrant-orange-800: #994313;
  --vibrant-orange-900: #662d0d;
  --vibrant-orange-1000: #331606;
  --dynamite-100: #ffece9;
  --dynamite-200: #ffd8d3;
  --dynamite-300: #ffb2a7;
  --dynamite-400: #ff8b7c;
  --dynamite-500: #ff6550;
  --dynamite-600: #ff3e24;
  --dynamite-700: #cc321d;
  --dynamite-800: #992516;
  --dynamite-900: #66190e;
  --dynamite-1000: #330c07;
  --red-stop-100: #ffe9e9;
  --red-stop-200: #ffd4d4;
  --red-stop-300: #fea8a8;
  --red-stop-400: #fe7d7d;
  --red-stop-500: #fd5151;
  --red-stop-600: #fd2626;
  --red-stop-700: #ca1e1e;
  --red-stop-800: #981717;
  --red-stop-900: #650f0f;
  --red-stop-1000: #330808;
  --in-the-red-100: #ffe8eb;
  --in-the-red-200: #ffd2d7;
  --in-the-red-300: #ffa4af;
  --in-the-red-400: #ff7787;
  --in-the-red-500: #ff495f;
  --in-the-red-600: #ff1c37;
  --in-the-red-700: #cc162c;
  --in-the-red-800: #991121;
  --in-the-red-900: #660b16;
  --in-the-red-1000: #33060b;
  --scarlet-cattleya-orchid-100: #f9e6ee;
  --scarlet-cattleya-orchid-200: #f2ccdc;
  --scarlet-cattleya-orchid-300: #e599b9;
  --scarlet-cattleya-orchid-400: #d96696;
  --scarlet-cattleya-orchid-500: #cc3373;
  --scarlet-cattleya-orchid-600: #bf0050;
  --scarlet-cattleya-orchid-700: #990040;
  --scarlet-cattleya-orchid-800: #730030;
  --scarlet-cattleya-orchid-900: #4c0020;
  --scarlet-cattleya-orchid-1000: #260010;
  --fuchsia-fever-100: #ffedf5;
  --fuchsia-fever-200: #ffdbea;
  --fuchsia-fever-300: #ffb8d6;
  --fuchsia-fever-400: #fe94c1;
  --fuchsia-fever-500: #fe71ad;
  --fuchsia-fever-600: #fe4d98;
  --fuchsia-fever-700: #cb3e7a;
  --fuchsia-fever-800: #982e5b;
  --fuchsia-fever-9h00: #661f3d;
  --fuchsia-fever-1000: #330f1e;
  --pisces-vivid-amethyst-100: #f7edfd;
  --pisces-vivid-amethyst-200: #eedafb;
  --pisces-vivid-amethyst-300: #ddb5f7;
  --pisces-vivid-amethyst-400: #cd90f4;
  --pisces-vivid-amethyst-500: #bc6bf0;
  --pisces-vivid-amethyst-600: #ab46ec;
  --pisces-vivid-amethyst-700: #8938bd;
  --pisces-vivid-amethyst-800: #672a8e;
  --pisces-vivid-amethyst-900: #441c5e;
  --pisces-vivid-amethyst-1000: #220e2f;
  --sea-grape-100: #ebe6f7;
  --sea-grape-200: #d7ccef;
  --sea-grape-300: #af99df;
  --sea-grape-400: #8866ce;
  --sea-grape-500: #6033be;
  --sea-grape-600: #3800ae;
  --sea-grape-700: #2d008b;
  --sea-grape-800: #220068;
  --sea-grape-900: #160046;
  --sea-grape-1000: #0b0023;
  --cloisonne-100: #e6f1f7;
  --cloisonne-200: #cce3ef;
  --cloisonne-300: #99c6df;
  --cloisonne-400: #66aad0;
  --cloisonne-500: #338dc0;
  --cloisonne-600: #0071b0;
  --cloisonne-700: #005a8d;
  --cloisonne-800: #00446a;
  --cloisonne-900: #002d46;
  --cloisonne-1000: #001723;
  --dilvant-100: #1f77fb;
  --dilvant-200: #1c78fd;
  --dilvant-300: #2565e8;
  --dilvant-400: #1f77f9;
  --bayern-blue-100: #e6f6fb;
  --bayern-blue-200: #ccecf6;
  --bayern-blue-300: #99d9ee;
  --bayern-blue-400: #66c7e5;
  --bayern-blue-500: #33b4dd;
  --bayern-blue-600: #00a1d4;
  --bayern-blue-700: #0081aa;
  --bayern-blue-800: #00617f;
  --bayern-blue-900: #004055;
  --bayern-blue-1000: #00202a;
  --electron-blue-100: #e6f2fb;
  --electron-blue-200: #cce5f7;
  --electron-blue-300: #99cbef;
  --electron-blue-400: #66b0e7;
  --electron-blue-500: #3396df;
  --electron-blue-600: #007cd7;
  --electron-blue-700: #0063ac;
  --electron-blue-800: #004a81;
  --electron-blue-900: #003256;
  --electron-blue-1000: #00192b;
  --fern-green-100: #e6f3ed;
  --fern-green-200: #cce7da;
  --fern-green-300: #99cfb5;
  --fern-green-400: #66b891;
  --fern-green-500: #33a06c;
  --fern-green-600: #008847;
  --fern-green-700: #006d39;
  --fern-green-800: #00522b;
  --fern-green-900: #00361c;
  --fern-green-1000: #001b0e;
  --candy-green-100: #ebfbe6;
  --candy-green-200: #d7f6cc;
  --candy-green-300: #afed99;
  --candy-green-400: #87e466;
  --candy-green-500: #5fdb33;
  --candy-green-600: #37d200;
  --candy-green-700: #2ca800;
  --candy-green-800: #217e00;
  --candy-green-900: #165400;
  --candy-green-1000: #0b2a00;
  --delicious-dill-100: #f2fae6;
  --delicious-dill-200: #e5f4cc;
  --delicious-dill-300: #cbea99;
  --delicious-dill-400: #b1df66;
  --delicious-dill-500: #97d533;
  --delicious-dill-600: #7dca00;
  --delicious-dill-700: #64a200;
  --delicious-dill-800: #4b7900;
  --delicious-dill-900: #325100;
  --delicious-dill-1000: #192800;
  --black-slug-100: #eae9e8;
  --black-slug-200: #d6d2d0;
  --black-slug-300: #ada5a1;
  --black-slug-400: #837973;
  --black-slug-500: #5a4c44;
  --black-slug-600: #311f15;
  --black-slug-700: #271911;
  --black-slug-800: #1d130d;
  --black-slug-900: #140c08;
  --black-slug-1000: #0a0604;
  --stone-cold-100: #eee;
  --stone-cold-200: #ddd;
  --stone-cold-300: #bbb;
  --stone-cold-400: #9a9a9a;
  --stone-cold-500: #787878;
  --stone-cold-600: #565656;
  --stone-cold-700: #454545;
  --stone-cold-800: #343434;
  --stone-cold-900: #222;
  --stone-cold-1000: #111;
  --stone-cold-1100: #131314;
  --celandine-green-100: #f8f8f7;
  --celandine-green-200: #f1f2ef;
  --celandine-green-300: #e3e5df;
  --celandine-green-400: #d4d7d0;
  --celandine-green-500: #c6cac0;
  --celandine-green-600: #b8bdb0;
  --celandine-green-700: #93978d;
  --celandine-green-800: #93978d;
  --celandine-green-900: #4a4c46;
  --celandine-green-1000: #4a4c46;
  --dire-wolf-100: #e9e9e9;
  --dire-wolf-200: #d4d4d4;
  --dire-wolf-300: #a9a9a9;
  --dire-wolf-400: #7d7d7d;
  --dire-wolf-500: #525252;
  --dire-wolf-600: #272727;
  --dire-wolf-700: #1f1f1f;
  --dire-wolf-800: #171717;
  --dire-wolf-900: #101010;
  --dire-wolf-1000: #080808;
  --tsg-primary: #e4d850;
  --tsg-white: #fff;
  --tsg-black: #0c0c0f;
  --tsg-black-secondary: #131314;
  --rg-primary: #174589;
  --rg-black: #232323;
  --rg-orange: #ff7f00;
}

.has-text-weight-extrabold {
  font-weight: 800;
}

.has-text-weight-black {
  font-family: 900;
}

.is-size-08 {
  font-size: 3.8rem;
}

.is-size-05 {
  font-size: 4.8rem;
}

*, :before, :after {
  box-sizing: inherit;
  border: 0;
  margin: 0;
  padding: 0;
}

.container__primary {
  max-width: 1344px;
  margin: 0 auto;
}

@media (width <= 1407px) and (width >= 1216px) {
  .container__primary {
    max-width: 85%;
  }
}

@media (width <= 1215px) {
  .container__primary {
    max-width: 90%;
  }
}

@media (width <= 768px) {
  .container__primary {
    max-width: 100%;
  }

  #addtocontact div {
    max-width: 90%;
  }
}

.header {
  background-position: center;
  background-size: cover;
  height: 453px;
}

@media (width <= 768px) {
  .header {
    background-position: center;
    height: 260px;
  }
}

@media (width <= 1023px) and (width >= 769px) {
  .header {
    background-position: center;
    height: 453px;
  }
}

.header-img {
  width: 100%;
  height: 100%;
}

.two_columns {
  grid-template-columns: 50% 50%;
  height: 100%;
  display: grid;
}

@media (width < 1024px) {
  .two_columns {
    grid-template-columns: 100%;
  }
}

.profile {
  border: solid var(--tsg-white);
  border-radius: 50%;
  width: 240px;
  height: 240px;
  margin: auto;
}

@media (width <= 768px) {
  .profile {
    width: 160px;
    height: 160px;
    bottom: -31%;
  }
}

@media (width >= 769px) and (width <= 1023px) {
  .profile {
    width: 190px;
    height: 190px;
    bottom: -16%;
  }
}

.header-content {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
}

@media (width <= 768px) {
  .header-content {
    background-position: center;
    height: 88%;
  }
}

@media (width >= 1024px) and (width <= 1350px) {
  .header-content {
    gap: 20px;
    position: absolute;
    top: 0;
    right: -96%;
  }
}

@media (width >= 1350px) {
  .header-content {
    gap: 20px;
    position: absolute;
    top: 0;
    right: -77%;
  }
}

@media (width >= 760px) and (width <= 1200px) {
  .header-content .header-logo {
    height: 42px;
  }
}

@media (width >= 1200px) {
  .header-content .header-logo {
    width: 600px;
    height: 48px;
  }
}

@media (width <= 500px) {
  .slider-description-srv {
    flex-direction: column;
    gap: 5px !important;
  }

  .slider-description-srv .line {
    width: 60px;
    transform: rotate(180deg);
    height: 4px !important;
  }
}

#container__seciton {
  column-count: 2;
  gap: 25px;
}

@media (width < 1024px) {
  #container__seciton {
    column-count: 1;
    margin-bottom: 0 !important;
  }
}

.is-hover-btn:hover {
  filter: brightness(.9);
}

@media (width <= 1150px) {
  .mobile-block {
    flex-direction: column;
    gap: 10px;
    display: flex;
  }
}

.qr-box {
  border: var(--rg-primary) solid 1px;
  border-radius: 12px;
}

@media (width <= 769px) {
  .qr-box {
    border: #0000;
    box-shadow: none !important;
  }
}

.alert {
  color: #fff;
  z-index: 100;
  background-color: #04aa6d;
  width: 100%;
  margin-bottom: 15px;
  padding: 20px;
  display: none;
  position: fixed;
  bottom: -15px;
  left: 0;
}

.services-grid {
  grid-template-rows: auto auto;
  grid-template-columns: auto auto;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding: 20px;
  display: grid;
}

.service-card {
  background-color: var(--tsg-primary);
  border-bottom-right-radius: 36px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0;
  display: flex;
}

.service-card img {
  width: 190px;
  height: 190px;
  margin: auto;
  padding: 2px;
}

.service-card p {
  max-width: 144px;
  height: 63.21px;
  margin: 8px 20px;
  font-size: 15px;
  display: block;
}

.closebtn {
  color: #fff;
  float: right;
  cursor: pointer;
  margin-left: 15px;
  font-size: 22px;
  font-weight: bold;
  line-height: 20px;
  transition: all .3s;
}

.closebtn:hover {
  color: #000;
}

.contact-link-btn {
  background-color: #fff;
  border: 5px solid #174589;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 55px;
  display: flex !important;
}

.contact-link-btn img {
  object-fit: contain;
}

.content__icon p {
  color: #174589 !important;
}

.modal input {
  width: 100%;
}

.copy-btn {
  cursor: pointer;
}

/*# sourceMappingURL=index.a18b5977.css.map */
